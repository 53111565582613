<template>
  <b-overlay
    :show="showOverlay"
    opacity="0.17"
    blur="1rem"
    rounded="md"
    variant="secondary"
  >
    <div v-if="task && taskForm" class="card">
      <div class="card-header border-bottom">
        <div
          class="d-flex flex-column align-items-start justify-content-start"
          style="width: 75%"
        >
          <div class="d-flex justify-content-start align-items-center w-100">
            <h4 class="mb-0 font-weight-bolder">
              <span class="align-middle"></span>{{ task.title }}
            </h4>
          </div>
          <div
            class="d-flex align-items-center justify-content-start mt-75"
            v-if="task.reference_type && task.reference_type != null"
          >
            <!-- <span class="font-weight-bold">Reference Type:</span
            > -->
            <b-badge variant="primary" class="ml-25">{{
              getTaskReferenceName(task.reference_type)
            }}</b-badge>
          </div>
        </div>

        <div class="d-flex justify-content-end align-items-center">
          <b-button
            size="sm"
            @click="closeSidebar()"
            variant="outline-secondary"
            ><feather-icon size="24" icon="XIcon"
          /></b-button>
        </div>
      </div>

      <div
        v-if="task.reference_type && task.reference_type != null"
        class="d-flex flex-row justify-content-center align-items-center content-sidebar-header px-2 py-1"
      >
        <b-button
          v-if="
            task.reference_type &&
            task.reference_type == 'App\\Models\\GapAssessmentResponse'
          "
          :disabled="taskForm.status == 4"
          variant="primary"
          class="my-75 mx-75"
          @click="handleUploadEvidencClick(task.callback_url)"
        >
          <feather-icon icon="CheckSquareIcon" class="mr-50" />
          <span class="align-middle">Upload Evidence</span>
        </b-button>

        <b-button
          v-if="
            task.reference_type &&
            task.reference_type == 'App\\Models\\GapAssessmentResponse'
          "
          :disabled="taskForm.status == 4"
          variant="success"
          class="my-75 mx-75"
          @click="handleGotoAssessmentClick"
        >
          <feather-icon icon="ArrowUpLeftIcon" class="mr-50" />
          <span class="align-middle">Go to Assessment</span>
        </b-button>

        <!-- <p class="my-3">Associated Risk:</p> -->
      </div>

      <div class="card-body mt-1">
        <validation-observer ref="task_form" #default="{ invalid }">
          <b-form @submit.prevent="onSubmitClick">
            <!-- Title -->
            <b-form-group label="Name">
              <template slot="label">
                Name <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required|min:4"
                vid="title"
              >
                <b-form-input
                  id="title"
                  v-model="taskForm.title"
                  :state="errors.length > 0 ? false : null"
                  name="Name"
                  :disabled="
                    task.creator &&
                    task.creator.user_id !== $store.state.app.user.user_id
                  "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Description -->
            <b-form-group label="Description">
              <validation-provider
                #default="{ errors }"
                name="Description"
                vid="description"
              >
                <!-- <b-form-input
                  id="description"
                  v-model="taskForm.description"
                  :state="errors.length > 0 ? false : null"
                  name="description"
                  :disabled="isTasksOfCurrentUser"
                /> -->
                <b-form-textarea
                  id="description"
                  v-model="taskForm.description"
                  :disabled="
                    task.creator &&
                    task.creator.user_id !== $store.state.app.user.user_id
                  "
                  debounce="500"
                  rows="3"
                  :state="errors.length > 0 ? false : null"
                  name="description"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Priority -->
            <b-form-group class="mt-1" label="Priority">
              <template slot="label">
                Priority <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Priority"
                rules="required"
                vid="priority"
              >
                <b-form-select
                  v-model="taskForm.priority"
                  :options="priorityOptions"
                  :disabled="
                    task.creator &&
                    task.creator.user_id !== $store.state.app.user.user_id
                  "
                ></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Status -->
            <b-form-group class="mt-1" label="Status">
              <template slot="label">
                Status <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Status"
                rules="required"
                vid="status"
              >
                <b-form-select
                  v-model="taskForm.status"
                  :options="
                    task.creator &&
                    task.creator.user_id !== $store.state.app.user.user_id
                      ? statusOptionsAssigneUser
                      : statusOptions
                  "
                ></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Assignee -->
            <b-form-group class="mt-1" label="Assignee">
              <template slot="label">
                Assignee <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Assignee"
                rules="required"
                vid="assignee"
              >
                <!-- <vue-autosuggest
                  :suggestions="assigneeOptions"
                  :limit="5"
                  v-model="assigneeName"
                  id="autosuggest__input"
                  :input-props="{
                    class: 'form-control',
                    placeholder: 'Search..',
                    disabled: taskForm.status == 4 && task.creator && task.creator.user_id!==$store.state.app.user.user_id,
                  }"
                  @input="getAssigneeBySearchPhrase"
                  @selected="onAssigneeSelected"
                >
                  <template
                    slot-scope="{ suggestion }"
                    style="display: flex; align-items: center"
                  >
                    <span style="{ display: 'flex', color: 'navyblue'}"
                      >{{ suggestion.item.firstname }}
                      {{ suggestion.item.lastname }}</span
                    >
                  </template>
                </vue-autosuggest> -->
                <b-form-select
                  v-model="taskForm.assignee"
                  :options="assigneeOptions"
                  :disabled="
                    taskForm.status == 4 &&
                    task.creator &&
                    task.creator.user_id !== $store.state.app.user.user_id
                  "
                ></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Due Date -->
            <b-form-group class="mt-1" label="Due Date">
              <template slot="label">
                Due Date <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Due Date"
                rules="required"
                vid="due_date"
              >
                <b-form-datepicker
                  id="due-date"
                  v-model="taskForm.due_date"
                  :min="minDueDate"
                  :disabled="
                    task.creator &&
                    task.creator.user_id !== $store.state.app.user.user_id
                  "
                ></b-form-datepicker>
                <small class="text-danger">{{
                  errors[0]
                }}</small></validation-provider
              ></b-form-group
            >

            <b-button
              type="submit"
              variant="primary"
              class="ml-0 mt-75"
              :disabled="invalid"
            >
              <feather-icon icon="CheckIcon" class="mr-50" />
              <span class="align-middle">Update</span>
            </b-button>
          </b-form>
        </validation-observer>
      </div>
      <div class="card">
        <div class="card-header py-50">
          <p class="card-title mb-0">Comments</p>
        </div>
        <div class="card-body">
          <comments-section
            :comments="task.comments"
            :newCommentCallback="handleAddNewComment"
            :showCommentLoading="showCommentLoading"
          />
        </div>
      </div>
      <!-- <div style="height: 100%" class="w-100 card bg-light-secondary">
      <div class="card-header">
        <h4 class="card-title">Comments</h4>
      </div>

      <div class="card-body">
        <div
          class="d-flex flex-column justify-content-between align-items-start"
        >
          <div
            class="card w-100 shadow"
            v-for="comment in task.comments"
            :key="comment._id['$oid']"
          >
            <div class="card-header"></div>
            <div class="card-body">
              {{ comment.message }}
            </div>
          </div>
        </div>

        <div class="d-flex flex-column justify-content-start align-items-start">
          <b-form-textarea
            id="textarea"
            v-model="comment"
            placeholder="write a new comment..."
            rows="4"
            max-rows="6"
          ></b-form-textarea>
          <b-button
            class="mt-1"
            @click="addCommentAndSetData()"
            variant="success"
            :disabled="comment === null || comment === ''"
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">Add Comment</span>
          </b-button>
        </div>
      </div>
    </div> -->
    </div>
  </b-overlay>
</template>

<script>
import {
  BForm,
  BFormInput,
  BFormGroup,
  BFormDatepicker,
  BInputGroupPrepend,
  BButton,
  BListGroup,
  BListGroupItem,
  BSidebar,
  BFormSelect,
  BFormTextarea,
  BOverlay,
  BBadge,
} from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { VueAutosuggest } from "vue-autosuggest";
import TaskMixins from "@/mixins/TaskMixins";
import ResponseMixins from "@/mixins/ResponseMixins";
import CommentMixins from "@/mixins/CommentMixins";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import CommentsSection from "../../../components/CommentsSection.vue";
import UserMixins from "../../../mixins/UserMixins";

export default {
  name: "TaskForm",
  components: {
    BForm,
    BFormInput,
    BFormGroup,
    BInputGroupPrepend,
    BButton,
    BListGroup,
    BListGroupItem,
    BSidebar,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    required,
    BFormDatepicker,
    VueAutosuggest,
    BFormSelect,
    ToastificationContent,
    BFormTextarea,
    CommentsSection,
    BOverlay,
    BBadge,
  },
  mixins: [TaskMixins, ResponseMixins, CommentMixins, UserMixins],
  props: {
    closeSidebar: {
      type: Function,
      required: true,
    },
    updateTaskStatusAndSetData: {
      type: Function,
      required: true,
    },
    // task: {
    //   required: true,
    //   type: Object,
    // },
    isTasksOfCurrentUser: {
      required: true,
      type: Boolean,
    },
    reloadParent: {
      required: true,
      type: Function,
    },

    taskId: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      comments: null,
      showOverlay: false,
      showCommentLoading: false,
      minDueDate: null,
      task: null,
      taskForm: {
        title: null,
        description: null,
        assigneee: null,
        status: null,
        due_date: null,
        priority: null,
        _id: null,
      },
      priorityOptions: [
        { value: null, text: "Select Task Priority" },
        { value: 1, text: "Low" },
        { value: 2, text: "Medium" },
        { value: 3, text: "High" },
      ],
      statusOptions: [
        { value: null, text: "Select Task Status" },
        { value: 1, text: "Pending" },
        { value: 2, text: "In Progress" },
        // { value: 3, text: "In Review" },
        // { value: 4, text: "Approved" },
        // { value: 5, text: "Rejected" },
      ],
      statusOptionsAssigneUser: [
        { value: null, text: "Select Task Status" },
        { value: 1, text: "Pending" },
        { value: 2, text: "In Progress" },
        { value: 3, text: "Completed" },
        // { value: 3, text: "In Review" },
      ],
      assigneeOptions: [{ text: "--Select Assignee--", value: null }],
      assigneeName: "",
    };
  },
  mounted() {
    this.load();
  },
  beforeDestroy() {
    if (this.$route.query && this.$route.query.task_id) {
      this.$router.replace({ query: null });
    }
  },
  // watch: {
  //   task(value) {
  //     this.setFormData(value);
  //   },
  // },
  methods: {
    load() {
      // this.setFormData(this.task);
      this.getTaskAndSetData(this.taskId);
      this.getAssigneeUsersAndSetData();
      // this.getCommentsAndSetData();
    },

    getTaskAndSetData(id) {
      this.showOverlay = true;
      this.getTask(id)
        .then((res) => {
          console.log("Task Response", res);
          let taskData = res.data.data;
          this.task = taskData;
          this.setFormData(taskData);
        })
        .catch((err) => {
          if (err.response && err.response.status == 404) {
            this.showToast("Task does not exist.", "danger");
          } else {
            this.handleError(err);
          }
          this.closeSidebar();
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    getTaskReferenceName(inputString) {
      // console.log(referenceType);
      // if (referenceType == "App\\Models\\GapAssessmentResponse") {
      //   return "Gap Assessment";
      // } else {
      //   return referenceType;
      // }

      return inputString.replace(/App\\Models\\/g, "");
    },

    handleUploadEvidencClick(url) {
      const self = this;
      url = url + "&task_idx=" + encodeURIComponent(this.taskId);
      console.log(url);
      this.$router.push({
        path: url,
        // replace: true,
        // query: {
        //   task_title: self.taskForm.title,
        //   task_description: self.taskForm.description,
        //   task_id: self.taskForm._id,
        // },
      });
    },

    handleGotoAssessmentClick() {
      const meta = this.task.meta_data;
      this.$router.push({
        path: `/gapassessment/${meta.gap_assessment_id}/controlgapassessment`,
        replace: true,
        query: {
          gap_assessment_response_id: meta.gap_assessment_response_id,
          control_family_id: meta.control_family_id,
        },
      });
    },

    setFormData(data) {
      console.log(data);
      this.taskForm.title = data.title;
      this.taskForm.description = data.description;
      let date = new Date();
      date.setDate(date.getDate() + 1);
      this.minDueDate = date;
      this.taskForm.due_date = data.due_date;
      this.taskForm.priority = Number(data.priority);
      this.taskForm.status = Number(data.status);
      this.taskForm.assignee =
        data.assignee_user && data.assignee_user != null
          ? data.assignee_user.user_id
          : null;
      this.taskForm._id = data._id;
      if (data.assignee_user && data.assignee_user != null) {
        this.assigneeName =
          data.assignee_user.firstname + " " + data.assignee_user.lastname;
      }
    },
    getAssigneeBySearchPhrase(searchPhrase) {
      if (searchPhrase !== "") {
        clearTimeout(this.timeout);
        const options = {
          method: "GET",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          url: `${process.env.VUE_APP_BASEURL}/users`,
          params: { search: searchPhrase, limit: 5 },
        };
        this.timeout = setTimeout(() => {
          this.$http(options)
            .then((res) => {
              this.assigneeOptions = [];
              let assignees = res.data.data.data;
              if (this.isTasksOfCurrentUser) {
                assignees = assignees.filter(
                  (assignee) => assignee.user_id !== this.task.creator.user_id
                );
              }

              this.assigneeOptions.push({
                name: "assignees",
                data: assignees,
              });
            })
            .catch((err) => {
              this.handleError(err);
              console.log(err);
            });
        }, this.debounceMilliseconds);
      } else {
        this.assigneeOptions = [];
        this.assigneeName = "";
      }
    },

    getAssigneeUsersAndSetData() {
      this.getUsers({ dont_paginate: true })
        .then((res) => {
          let newUsers = res.data.data;
          newUsers.map((user) => {
            this.assigneeOptions.push({
              text: `${user.firstname} ${user.lastname}`,
              value: user.user_id,
            });
          });
        })
        .catch((err) => {
          this.handleError(err);
        });
    },

    onAssigneeSelected(assignee) {
      this.taskForm.assignee = assignee.item;
      this.assigneeName =
        assignee.item.firstname + " " + assignee.item.lastname;
    },

    resetFormData() {
      this.taskForm = {
        title: null,
        description: null,
        assigneee: null,
        status: null,
        due_date: null,
        priority: null,
        _id: null,
      };
    },
    onSubmitClick() {
      if (
        this.task.creator &&
        this.task.creator.user_id !== this.$store.state.app.user.user_id
      ) {
        this.taskForm.title = null;
        this.taskForm.description = null;
        this.taskForm.due_date = null;
        this.taskForm.priority = null;
      }
      let obj = this.taskForm;
      obj["id"] = this.task._id;
      this.updateTaskAndSetData(obj);
    },

    updateTaskAndSetData(obj) {
      this.updateTask(obj)
        .then((res) => {
          this.handleResponse(res);
          this.reloadParent();
          this.closeSidebar();
          this.resetFormData();
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
          if (err.response.status === 422) {
            this.$refs.task_form.setErrors(err.response.data.errors);
          }
        });
    },

    getCommentsAndSetData() {
      this.getComments(this.task._id, {})
        .then((res) => {
          // const comments = res.data.data.data;
          this.comments = res.data.data.data;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        });
    },

    handleAddNewComment(comment) {
      return new Promise((resolve, reject) => {
        this.addNewCommentAndSetData(comment)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    addNewCommentAndSetData(comment) {
      return new Promise((resolve, reject) => {
        this.showCommentLoading = true;
        this.addNewComment(this.taskId, { comment: comment })
          .then((res) => {
            this.handleResponse(res);
            this.load();
            this.reloadParent();
            resolve(res);
          })
          .catch((err) => {
            this.handleError(err);
            reject(err);
          })
          .finally(() => {
            this.showCommentLoading = false;
          });
      });
    },
  },
};
</script>

<style></style>
