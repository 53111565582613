<template>
  <div class="h-auto w-100">
    <b-sidebar
      id="sidebar-backdrop"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
      lazy
    >
      <TaskForm
        v-if="
          selectedTaskIndex !== null ||
          (selectedTaskIndex !== -1 && tasks.length > 0)
        "
        :closeSidebar="closeSidebar"
        :task="tasks[selectedTaskIndex]"
        :isTasksOfCurrentUser="isTasksOfCurrentUser"
        :updateTaskStatusAndSetData="updateTaskStatusAndSetData"
        :reloadParent="load"
        :taskId="selectedTaskId"
      />
    </b-sidebar>
    <b-modal
      ref="download-modal"
      hide-footer
      title="Task Download Options"
      size="lg"
    >
      <div class="d-flex flex-column align-items-center justify-content-start">
        <div class="w-100 d-flex align-items-center justify-content-center">
          <p class="font-weight-bolder mb-0">
            Choose how you want to download Tasks
          </p>
        </div>
        <div
          class="d-flex align-items-center justify-content-start mt-1"
          style="width: 200px"
        >
          <b-form-checkbox
            id="all-matching"
            :disabled="downloadFilters.allSelected"
            v-model="downloadFilters.allMatchingFilters"
          >
            All matching filters.
          </b-form-checkbox>
        </div>
        <div
          class="d-flex align-items-center justify-content-start mt-50"
          style="width: 200px"
        >
          <b-form-checkbox
            id="all-selected"
            v-model="downloadFilters.allSelected"
            :disabled="
              downloadFilters.allMatchingFilters || allTasksSelect === true
            "
          >
            Selected.
          </b-form-checkbox>
        </div>
        <div
          class="d-flex align-items-center justify-content-start mt-1 mb-1"
          style="width: 240px"
        >
          <b-button
            @click="handleTaskDownload()"
            variant="success"
            size="md"
            block
          >
            <feather-icon icon="DownloadIcon" class="mr-50" />Download
          </b-button>
        </div>
      </div>
    </b-modal>
    <b-overlay
      opacity="0.17"
      blur="1rem"
      :show="showOverlay"
      rounded="md"
      variant="secondary"
    >
      <div class="todo-application h-100">
        <div class="content-area-wrapper">
          <div class="sidebar-left">
            <div class="sidebar">
              <div class="sidebar-content todo-sidebar">
                <div class="todo-app-menu">
                  <div class="add-task p-50">
                    <!-- <b-button
                      @click="$router.push('risk-register')"
                      variant="primary"
                      block
                      >Add Task</b-button
                    > -->
                  </div>
                  <div class="sidebar-menu-list w-100 h-100 pb-2">
                    <!-- Filters -->
                    <vue-perfect-scrollbar
                      :settings="perfectScrollbarSettings"
                      class="ps-customizer-area scroll-area"
                      style="width: 100%; z-index: 2; height: 100%"
                    >
                      <b-list-group class="list-group-filters">
                        <template v-for="(option, i) in staticTaskOptions">
                          <b-list-group-item
                            :key="i"
                            :class="{ active: option.isActive }"
                            class="cursor-pointer"
                            @click="option.func"
                            ><feather-icon
                              :icon="option.icon"
                              size="18"
                              class="mr-75"
                            />
                            <span class="align-text-bottom line-height-1">{{
                              option.title
                            }}</span>
                          </b-list-group-item>
                        </template>

                        <!-- Assessments Section -->
                        <template
                          
                          v-if="
                            dynamicTaskOptions && dynamicTaskOptions.length > 0
                          "
                        >
                          <h6 class="section-label mt-3 mb-25 px-2">
                            Assessments
                          </h6>
                          <template v-for="option in dynamicTaskOptions">
                            <b-list-group-item
                              :key="option._id"
                              :class="{ active: option.isActive }"
                              class="cursor-pointer py-25 mt-50"
                              @click="option.func"
                              ><feather-icon
                                v-if="
                                  option.icon &&
                                  option.icon != null &&
                                  option.icon !== ''
                                "
                                :icon="option.icon"
                                size="18"
                                class="mr-75"
                              />
                              <small
                                class="font-weight-bold"
                                >{{ option.title }}</small
                              >
                            </b-list-group-item>
                            <!-- <template
                              v-if="
                                option.standard_subset &&
                                option.standard_subset !== null
                              "
                            >
                              <div
                                class="d-flex flex-row align-items-center justify-content-between w-100 mx-2 position-relative"
                                :key="option.standard_subset._id"
                              >
                                <div
                                  class="d-flex flex-row align-items-center justify-content-start w-100"
                                >
                                  <small class="m-0 text-truncate"
                                    >Subset:
                                  </small>
                                  <b-badge
                                    v-b-tooltip.hover.right.v-primary
                                    :title="option.standard_subset.name"
                                    variant="success"
                                    class="ml-25 text-truncate"
                                    style="max-width: 60%"
                                    >{{ option.standard_subset.name }}</b-badge
                                  >
                                </div>
                              </div>
                            </template> -->
                          </template>
                        </template>
                      </b-list-group>
                    </vue-perfect-scrollbar>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="content-right h-100" style="position: relative">
            <div class="card h-100">
              <!-- <div class="d-flex flex-row align-items-center justify-content-around">

              </div> -->
              <div
                class="w-100 d-flex align-items-center justify-content-center py-50"
              >
                <div
                  style="min-width: 160px"
                  v-if="isTasksOfCurrentUser"
                  class="mx-50"
                >
                  <b-form-select
                    size="md"
                    v-model="filters.created_by"
                    :options="assignerOptions"
                  ></b-form-select>
                </div>

                <div style="min-width: 160px" v-else class="mx-50">
                  <b-form-select
                    size="md"
                    v-model="filters.assignee_id"
                    :options="assigneeOptions"
                  ></b-form-select>
                </div>

                <div style="min-width: 160px">
                  <b-form-select
                    size="md"
                    v-model="filters.status"
                    :options="statusOptions"
                  ></b-form-select>
                </div>

                <b-form-group class="mx-50 mb-0" style="min-width: 160px">
                  <vue-autosuggest
                    class="border rounded"
                    :suggestions="departmentOptions"
                    :limit="5"
                    v-model="departmentName"
                    id="autosuggest__input"
                    :input-props="departmentInputProps"
                    @input="getDepartmentsBySearchPhrase"
                    @selected="onDepartmentSelected"
                  >
                    <template slot-scope="{ suggestion }">
                      <span >{{
                        suggestion.item.title
                      }}</span>
                    </template>
                  </vue-autosuggest>
                </b-form-group>
              </div>

              <div
                class="app-fixed-search d-flex align-items-center justify-content-between"
              >
                <div
                  class="d-flex align-content-center justify-content-between w-75"
                >
                  <b-button
                    v-if="!checkFiltersActive()"
                    @click="resetFilters"
                    variant="danger"
                    v-b-tooltip.hover
                    title="Refresh Filters"
                    ><feather-icon icon="RefreshCwIcon"
                  /></b-button>
                  <b-input-group>
                    <b-input-group-prepend is-text>
                      <feather-icon icon="SearchIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="filters.search"
                      placeholder="Search Name, Description..."
                    ></b-form-input>
                  </b-input-group>
                </div>

                <b-button
                  @click="handleDownloadClick"
                  variant="success"
                  size="md"
                >
                  <feather-icon icon="DownloadIcon" class="mr-50" />Download
                  Tasks
                </b-button>
              </div>

              <!-- <div class="table-wrapper-scroll-y my-custom-scrollbar"> -->
              <div class="table-responsive">
                <table role="table" class="table table-hover">
                  <thead role="rowgroup">
                    <tr role="row">
                      <th role="columnheader" scope="col">
                        <div
                          class="d-flex align-items-center justify-content-start"
                        >
                          <b-form-checkbox
                            v-model="allTasksSelect"
                            v-on:change="handleAllTaskSelectClick($event)"
                            class="mr-50"
                          >
                          </b-form-checkbox>
                          <span>Name</span>
                        </div>
                      </th>
                      <th role="columnheader" scope="col">Description</th>
                      <th role="columnheader" scope="col" class="text-center">
                        Status
                      </th>
                      <th role="columnheader" scope="col" class="text-center">
                        Priority
                      </th>
                      <th role="columnheader" scope="col">Created At</th>
                      <th role="columnheader" scope="col">Due Date</th>
                      <th
                        role="columnheader"
                        scope="col"
                        v-if="isTasksOfCurrentUser"
                      >
                        Assigner
                      </th>
                      <th role="columnheader" scope="col" v-else>Assignee</th>
                      <th role="columnheader" scope="col" class="text-center">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody role="rowgroup">
                    <template v-if="tasks && tasks.length > 0">
                      <tr
                        class="cursor-pointer"
                        v-for="task in tasks"
                        :key="task._id"
                      >
                        <td role="cell" style="width: 9vw; padding-right: 0">
                          <div
                            style="width: 9vw"
                            class="d-flex align-items-center justify-content-start"
                          >
                            <b-form-checkbox
                              v-on:change="handleTaskSelect($event, task._id)"
                              class="mr-50"
                            ></b-form-checkbox>
                            <p
                              @click="openSidebar(task._id)"
                              class="text-left text-truncate font-weight-bolder mb-0"
                              v-b-tooltip.hover.top.v-secondary
                              :title="task.title"
                            >
                              {{ task.title }}
                            </p>
                          </div>
                        </td>

                        <td
                          aria-colindex="2"
                          role="cell"
                          style="width: 10vw; max-width: 15vw; padding-right: 0"
                          @click="openSidebar(task._id)"
                        >
                          <div
                            style="width: 10vw; max-width: 15vw"
                            class="d-flex justify-content-start"
                          >
                            <p
                              class="text-left text-truncate font-weight-bold mb-0"
                              v-b-tooltip.hover.top.v-secondary
                              :title="task.description"
                            >
                              {{ task.description }}
                            </p>
                          </div>
                        </td>

                        <td
                          aria-colindex="2"
                          role="cell"
                          style="padding: 0"
                          @click="openSidebar(task._id)"
                        >
                          <div class="d-flex justify-content-center">
                            <b-badge
                              :variant="getStatusColor(Number(task.status))"
                              >{{
                                getStatusTextFromNumber(Number(task.status))
                              }}</b-badge
                            >
                            <!-- <b-button
                            v-b-tooltip.hover.top.v-secondary
                            :title="getStatusText(Number(task.status))"
                            :variant="
                              'flat-' + getStatusColor(Number(task.status))
                            "
                            class="btn-icon"
                          >
                            <feather-icon
                              :icon="getStatusIcon(Number(task.status))"
                            />
                          </b-button> -->
                          </div>
                        </td>

                        <td
                          aria-colindex="2"
                          role="cell"
                          style="padding: 0"
                          @click="openSidebar(task._id)"
                        >
                          <div class="d-flex justify-content-center">
                            <b-badge
                              :variant="getPriorityColor(Number(task.priority))"
                              >{{
                                getPriorityNumber(Number(task.priority))
                              }}</b-badge
                            >
                          </div>
                        </td>

                        <td
                          aria-colindex="2"
                          role="cell"
                          style="width: 90px; padding-right: 0px"
                          @click="openSidebar(task._id)"
                        >
                          <div
                            class="d-flex justify-content-start"
                            style="width: 90px"
                          >
                            <p class="mb-0 font-weight-bold">
                              {{ task.created_at | moment }}
                            </p>
                          </div>
                        </td>

                        <td
                          aria-colindex="2"
                          role="cell"
                          style="width: 90px; padding-right: 0px"
                          @click="openSidebar(task._id)"
                        >
                          <div
                            class="d-flex justify-content-start"
                            style="width: 90px"
                          >
                            <p class="mb-0 font-weight-bold">
                              {{ task.due_date | moment }}
                            </p>
                          </div>
                        </td>

                        <td
                          aria-colindex="2"
                          role="cell"
                          v-if="isTasksOfCurrentUser"
                          style="width: 100px; padding-right: 0"
                          @click="openSidebar(task._id)"
                        >
                          <div
                            class="d-flex justify-content-start"
                            style="width: 100px"
                            v-if="task.creator && task.creator != null"
                            v-b-tooltip.hover.top.v-secondary
                            :title="
                              task.creator.firstname +
                              ' ' +
                              task.creator.lastname
                            "
                          >
                            <p class="mb-0 font-weight-bold text-truncate">
                              {{ task.creator ? task.creator.firstname : "" }}
                              {{ task.creator ? task.creator.lastname : "" }}
                            </p>
                          </div>
                          <b-badge v-else variant="danger"
                            >Deleted User</b-badge
                          >
                        </td>

                        <td
                          aria-colindex="2"
                          role="cell"
                          v-else
                          style="width: 100px; padding-right: 0"
                          @click="openSidebar(task._id)"
                        >
                          <div
                            class="d-flex justify-content-start"
                            style="width: 100px"
                            v-if="task.assignee_user"
                            v-b-tooltip.hover.top.v-secondary
                            :title="
                              task.assignee_user.firstname +
                              ' ' +
                              task.assignee_user.lastname
                            "
                          >
                            <p class="mb-0 font-weight-bold text-truncate">
                              {{
                                task.assignee_user
                                  ? task.assignee_user.firstname
                                  : ""
                              }}
                              {{
                                task.assignee_user
                                  ? task.assignee_user.lastname
                                  : ""
                              }}
                            </p>
                          </div>
                          <b-badge v-else variant="danger"
                            >Deleted User</b-badge
                          >
                        </td>

                        <td
                          aria-colindex="2"
                          role="cell"
                          style="padding: 0"
                          @click="openSidebar(task._id)"
                        >
                          <div class="d-flex justify-content-center">
                            <!-- <b-button
                            @click.stop="handleDeleteClick(task._id)"
                            style="width: 100px"
                            variant="danger"
                            size="sm"
                            ><feather-icon
                              icon="TrashIcon"
                              class="mr-50"
                            /><span class="align-middle">Delete</span></b-button
                          > -->
                            <b-button
                              v-if="
                                (!isTasksOfCurrentUser && task.status == 1) ||
                                (task.creator &&
                                  task.creator.user_id ===
                                    $store.state.app.user.user_id &&
                                  task.status == 1)
                              "
                              @click.stop="showConfirmDeleteBox(task._id)"
                              variant="flat-danger"
                              class="btn-icon"
                              v-b-tooltip.hover.top.v-secondary
                              title="Delete Task"
                            >
                              <feather-icon icon="TrashIcon" />
                            </b-button>
                          </div>
                        </td>
                      </tr>
                    </template>
                    <template v-else>
                      <empty-table-section title="Tasks Not Found">
                        <template #content>
                          <p class="font-weight-bold text-center">
                            It seems like there are no Tasks at the moment.
                          </p>
                        </template>
                      </empty-table-section>
                    </template>
                  </tbody>
                </table>
              </div>
              <div
                class="w-100 d-flex align-items-center justify-content-center px-1 py-25 position-relative"
              >
                <b-pagination
                  class="mt-1"
                  v-model="filters.currentPage"
                  :per-page="pagination.perPage"
                  :total-rows="pagination.totalPages * pagination.perPage"
                  aria-controls="my-table"
                ></b-pagination>

                <div
                  class="position-absolute d-flex align-items-center justify-content-center mr-1 p-50"
                  style="right: 0"
                >
                  <p class="mb-0 font-weight-bolder">
                    Total:
                    <b-badge pill variant="primary">{{
                      pagination.total
                    }}</b-badge>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BForm,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BButton,
  BListGroup,
  BListGroupItem,
  BSidebar,
  BCard,
  BBadge,
  BOverlay,
  BFormSelect,
  VBTooltip,
  BPagination,
  BAvatar,
  BFormCheckbox,
} from "bootstrap-vue";
import TaskMixins from "../../mixins/TaskMixins";
import UserMixins from "../../mixins/UserMixins";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import draggable from "vuedraggable";
import { VBToggle } from "bootstrap-vue";
import TaskForm from "./components/TaskForm.vue";
import { VueAutosuggest } from "vue-autosuggest";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ResponseMixins from "../../mixins/ResponseMixins";
import GapAssessmentMixins from "@/mixins/GapAssessmentMixins";
import moment from "moment";
import EmptyTableSection from "@/components/EmptyTableSection.vue";
export default {
  name: "Tasks",
  components: {
    BForm,
    BFormInput,
    BInputGroup,
    BFormGroup,
    BInputGroupPrepend,
    BPagination,
    BButton,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
    draggable,
    BSidebar,
    BCard,
    VBToggle,
    TaskForm,
    BBadge,
    BOverlay,
    BFormSelect,
    VueAutosuggest,
    VBTooltip,
    ToastificationContent,
    BAvatar,
    BFormCheckbox,
    EmptyTableSection,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  filters: {
    moment: function (date) {
      return moment(date).format("D-MMM-YY");
    },
  },
  data() {
    return {
      perfectScrollbarSettings: { maxScrollbarLength: 60 },
      tasks: [],
      downloadingList: [],
      currentUserId: null,
      isTasksOfCurrentUser: true,
      isTasksOfAssessment: false,
      selectedTask: null,
      selectedTaskId: null,
      selectedTaskIndex: null,
      showOverlay: false,
      pagination: {
        total: 0,
        totalPages: 1,
        perPage: 10,
      },
      filters: {
        search: null,
        status: null,
        department_id: null,
        currentPage: 1,
        created_by: null,
        assignee_id: null,
        gap_assessment_id: null,
      },

      assignerOptions: [],
      assigneeOptions: [],
      statusOptions: [
        { value: null, text: "--Select Task Status--" },
        { value: 1, text: "Pending" },
        { value: 2, text: "In Progress" },
        { value: 3, text: "In Review" },
        { value: 4, text: "Approved" },
        { value: 5, text: "Rejected" },
      ],
      departmentOptions: [],
      departmentName: "",
      departmentInputProps: {
        class: "form-control",
        placeholder: "Search Department..",
      },
      taskOptions: [
        {
          title: "My Tasks",
          icon: "UserIcon",
          func: () => {
            this.handleMyTasksClick();
          },
          isActive: true,
          isAssessment: false,
          _id: "my_tasks",
        },
        {
          title: "Assigned Tasks",
          icon: "UsersIcon",
          func: () => {
            this.handleTasksAssignedClick();
          },
          isActive: false,
          isAssessment: false,
          _id: "assigned_tasks",
        },
      ],

      taskAssessments: {},

      selectedTasks: [],
      downloadFilters: {
        allMatchingFilters: false,
        allSelected: false,
      },
      allTasksSelect: false,
    };
  },

  computed: {
    staticTaskOptions() {
      return this.taskOptions.filter((opt) => opt.isAssessment === false);
    },

    dynamicTaskOptions() {
      return this.taskOptions.filter((opt) => opt.isAssessment === true);
    },
  },

  watch: {
    allTasksSelect(value) {
      if (value === true) {
        this.downloadFilters.allSelected = true;
      } else {
        this.downloadFilters.allSelected = false;
      }
    },
    isTasksOfCurrentUser(value) {
      this.getUsersAndSetData();
      if (value === true && this.isTasksOfAssessment === false) {
        this.getTasksAndSetData({
          page: this.filters.currentPage,
          assignee_id: this.$store.state.app.user.user_id,
          ...this.filters,
        });
      } else if (value === false && this.isTasksOfAssessment === false) {
        this.getTasksAndSetData({
          page: this.filters.currentPage,
          ...this.filters,
        });
      } else {
        this.getTasksAndSetData({
          page: this.filters.currentPage,
          ...this.filters,
        });
      }
    },
    filters: {
      handler: function (newFilters) {
        if (this.isTasksOfCurrentUser == true) {
          this.getTasksAndSetData({
            page: this.filters.currentPage,
            assignee_id: this.$store.state.app.user.user_id,
            ...this.filters,
          });
        } else if (
          this.isTasksOfCurrentUser === false &&
          this.isTasksOfAssessment === false
        ) {
          this.getTasksAndSetData({
            page: this.filters.currentPage,
            ...this.filters,
          });
        } else {
          this.getTasksAndSetData({
            page: newFilters.currentPage,
            ...newFilters,
          });
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.load();
    this.onVerticalCollapse();
  },
  mixins: [TaskMixins, UserMixins, ResponseMixins, GapAssessmentMixins],
  methods: {
    onVerticalCollapse() {
      let coll = true;
      this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", coll);
    },
    load() {
      if (this.$route.query && this.$route.query["task_id"]) {
        this.openSidebar(this.$route.query.task_id);
      }
      this.getUsersAndSetData();
      this.getSharedGapAssessmentsAndSetData({});
      if (this.isTasksOfCurrentUser) {
        this.getTasksAndSetData({
          page: this.filters.currentPage,
          assignee_id: this.$store.state.app.user.user_id,
          ...this.filters,
        });
      } else {
        this.getTasksAndSetData({
          page: this.filters.currentPage,
          ...this.filters,
        });
        this.handleTasksAssignedClick();
      }
    },

    handleAssessmentClick(id) {
      //  this.taskOptions[0].isActive = true;
      this.isTasksOfAssessment = true;
      this.isTasksOfCurrentUser = false;
      let newTaskOptions = this.taskOptions.map((opt) => {
        if (opt.isAssessment === true && opt._id && opt._id === id) {
          opt.isActive = true;
        } else {
          opt.isActive = false;
        }
        return opt;
      });
      this.taskOptions = newTaskOptions;
      let newFilters = {
        ...this.filters,
        assignee_id: null,
        created_by: null,
        gap_assessment_id: id,
      };
      this.filters = newFilters;
    },

    getSharedGapAssessmentsAndSetData(params) {
      this.getSharedGapAssessments(params)
        .then((res) => {
          let newTaskOptions = [...this.taskOptions];
          newTaskOptions = newTaskOptions.filter(
            (assmnt) => assmnt.isAssessment != true
          );
          console.log("Shared Gap Assessments:", res.data.data);
          res.data.data.forEach((assessment) => {
            newTaskOptions.push({
              title: assessment.title,
              func: () => {
                this.handleAssessmentClick(assessment._id);
              },
              icon: "",
              isActive: false,
              isAssessment: true,
              standard_subset: assessment.standard_subset,
              _id: assessment._id,
            });
          });
          this.taskOptions = newTaskOptions;
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        });
    },

    handleAllTaskSelectClick(value) {
      let newTasks = this.tasks.map((task) => {
        task.selected = value;
        return task;
      });
      this.tasks = newTasks;
    },

    handleTaskSelect(value, taskId) {
      if (value === true) {
        this.selectedTasks.push(taskId);
      } else {
        const newTasks = this.selectedTasks.filter(
          (task_id) => task_id !== taskId
        );
        this.selectedTasks = newTasks;
      }
    },

    handleDownloadClick() {
      this.showDownloadModal();
    },

    hideDownloadModal() {
      this.$refs["download-modal"].hide();
    },
    showDownloadModal() {
      this.$refs["download-modal"].show();
    },

    getUsersAndSetData() {
      this.getUsers({ dont_paginate: true })
        .then((res) => {
          let newUsers = res.data.data;
          if (this.isTasksOfCurrentUser) {
            this.assignerOptions = [
              { value: null, text: "--Select Assigner--" },
            ];
            newUsers.map((user) => {
              this.assignerOptions.push({
                text: `${user.firstname} ${user.lastname}`,
                value: user.user_id,
              });
            });
          } else {
            this.assigneeOptions = [
              { value: null, text: "--Select Assignee--" },
            ];
            newUsers.map((user) => {
              this.assigneeOptions.push({
                text: `${user.firstname} ${user.lastname}`,
                value: user.user_id,
              });
            });
          }
        })
        .catch((err) => {
          this.handleError(err);
        });
    },

    handleTaskDownload() {
      let params = {};
      if (this.isTasksOfCurrentUser === false) {
        params["created_by"] = this.$store.state.app.user.user_id;
      } else {
        params["assignee_id"] = this.$store.state.app.user.user_id;
      }

      console.log("DfIL", this.downloadFilters);

      if (this.downloadFilters.allMatchingFilters === true) {
        params["search"] = this.filters.search;
        params["status"] = this.filters.status;
        params["department_id"] = this.filters.department_id;
      } else if (this.downloadFilters.allSelected === true) {
        params["task_ids"] = this.selectedTasks;
      }

      console.log("Params", params);

      this.downloadTasks(params)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "tasks.csv");
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        });
    },

    getStatusIcon(value) {
      if (value && value !== null) {
        if (value == 1) {
          return "ClockIcon";
        } else if (value == 2) {
          return "TrendingUpIcon";
        } else if (value == 3) {
          return "EyeIcon";
        } else if (value == 4) {
          return "ThumbsUpIcon";
        } else if (value == 5) {
          return "SlashIcon";
        }
      }
    },

    getStatusText(value) {
      if (value && value !== null) {
        if (value == 1) {
          return "Pending";
        } else if (value == 2) {
          return "In Progress";
        } else if (value == 3) {
          return "In Review";
        } else if (value == 4) {
          return "Approved";
        } else if (value == 5) {
          return "Rejected";
        }
      }
    },

    showConfirmDeleteBox(taskId) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete the Task?", {
          title: "Confirm Delete Action",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            this.handleDeleteClick(taskId);
          }
        });
    },

    handleDeleteClick(id) {
      this.removeTask(id)
        .then((res) => {
          this.load();
          this.handleResponse(res);
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        });
    },

    getStatusTextFromNumber(num) {
      switch (num) {
        case 1:
          return "Pending";
        case 2:
          return "In Progress";
        case 3:
          return "In Review";
        case 4:
          return "Approved";
        case 5:
          return "Rejected";
        default:
          return "Pending";
      }
    },

    getStatusColor(num) {
      switch (num) {
        case 1:
          return "secondary";
        case 2:
          return "primary";
        case 3:
          return "warning";
        case 4:
          return "success";
        case 5:
          return "danger";
        default:
          return "info";
      }
    },

    getPriorityNumber(num) {
      switch (num) {
        case 1:
          return "Low";
        case 2:
          return "Medium";
        case 3:
          return "High";
        default:
          return "Low";
      }
    },

    getPriorityColor(num) {
      switch (num) {
        case 1:
          return "success";
        case 2:
          return "warning";
        case 3:
          return "danger";
        default:
          return "success";
      }
    },

    checkFiltersActive() {
      const value = Object.values(this.filters).every((value) => {
        if (value === null) {
          return true;
        }

        return false;
      });
      return value;
    },

    resetFilters() {
      this.filters = {
        search: null,
        status: null,
        currentPage: 1,
        department_id: null,
        created_by: null,
        assignee_id: null,
        gap_assessment_id: null,
      };
    },

    getTasksAndSetData(params) {
      this.showOverlay = true;
      let extraParams = {};
      if (
        this.isTasksOfCurrentUser === false &&
        this.isTasksOfAssessment === false
      ) {
        extraParams["created_by"] = this.$store.state.app.user.user_id;
      } else if (
        this.isTasksOfCurrentUser === true &&
        this.isTasksOfAssessment === false
      ) {
        if (
          params["assignee_id"] === null ||
          "assignee_id" in params === false
        ) {
          extraParams["assignee_id"] = this.$store.state.app.user.user_id;
        }
      }
      this.getTasks({
        ...params,
        ...extraParams,
      })
        .then((res) => {
          let newTasks = res.data.data.data;
          this.tasks = newTasks.map((task) => {
            task["selected"] = false;
            return task;
          });
          this.pagination.total = Number(res.data.data.total);
          this.pagination.totalPages = Number(res.data.data.last_page);
          this.filters.currentPage = Number(res.data.data.current_page);
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    getDepartmentsBySearchPhrase(searchPhrase) {
      if (searchPhrase !== "") {
        clearTimeout(this.timeout);
        const options = {
          method: "GET",
          url: `${process.env.VUE_APP_BASEURL}/departments?dont_paginate`,
        };
        this.timeout = setTimeout(() => {
          this.$http(options)
            .then((res) => {
              this.departmentOptions = [];
              let departments = res.data.data.data;
              this.departmentOptions.push({
                name: "department",
                data: departments,
              });
            })
            .catch((err) => {
              console.log(err);
              this.handleError(err);
            });
        }, this.debounceMilliseconds);
      } else {
        this.departmentOptions = [];
        this.departmentName = "";
      }
    },

    onDepartmentSelected(department) {
      this.filters.department_id = department.item._id;
      this.departmentName = department.item.title;
    },

    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
      this.selectedTask = null;
      if (this.$route.query && this.$route.query.task_id) {
        this.$router.replace({ query: null });
      }
    },

    openSidebar: function (id) {
      // const taskIndex = this.tasks.findIndex((task) => task._id === id);
      // this.selectedTaskIndex = taskIndex;
      // this.selectedTask = this.tasks[taskIndex];
      this.selectedTaskId = id;
      this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
    },

    handleMyTasksClick() {
      this.isTasksOfAssessment = false;
      this.isTasksOfCurrentUser = true;

      // this.taskOptions[0].isActive = true;
      // this.taskOptions[1].isActive = false;

      let newTaskOptions = this.taskOptions.map((opt) => {
        if (opt.isAssessment === false && opt._id && opt._id === "my_tasks") {
          opt.isActive = true;
        } else {
          opt.isActive = false;
        }
        return opt;
      });
      this.taskOptions = newTaskOptions;

      let newFilters = {
        ...this.filters,
        gap_assessment_id: null,
      };
      this.filters = newFilters;
    },

    handleTasksAssignedClick() {
      this.isTasksOfAssessment = false;
      this.isTasksOfCurrentUser = false;
      let newTaskOptions = this.taskOptions.map((opt) => {
        if (
          opt.isAssessment === false &&
          opt._id &&
          opt._id === "assigned_tasks"
        ) {
          opt.isActive = true;
        } else {
          opt.isActive = false;
        }
        return opt;
      });
      this.taskOptions = newTaskOptions;

      let newFilters = {
        ...this.filters,
        gap_assessment_id: null,
      };
      this.filters = newFilters;
    },

    updateTaskStatusAndSetData(obj) {
      this.updateTaskStatus(obj)
        .then((res) => {
          this.getTasksAndSetData({ page: this.filters.currentPage });
          this.handleResponse(res);
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        });
    },
    updateTaskAndSetData(obj) {
      this.updateTask(obj)
        .then((res) => {
          this.load();
          this.handleResponse(res);
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        });
    },
  },
};
</script>

<style lang="scss">
.b-sidebar.sidebar-lg {
  width: 60rem !important;
}
</style>

<style lang="scss" scoped>
.draggable-task-handle {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: move;

  .todo-task-list .todo-item:hover & {
    visibility: visible;
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-todo.scss";

.my-custom-scrollbar {
  position: relative;
  height: 100%;
  overflow: auto;
}

.table-wrapper-scroll-y {
  display: block;
}
</style>
