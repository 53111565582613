var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.showOverlay,"opacity":"0.17","blur":"1rem","rounded":"md","variant":"secondary"}},[(_vm.task && _vm.taskForm)?_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header border-bottom"},[_c('div',{staticClass:"d-flex flex-column align-items-start justify-content-start",staticStyle:{"width":"75%"}},[_c('div',{staticClass:"d-flex justify-content-start align-items-center w-100"},[_c('h4',{staticClass:"mb-0 font-weight-bolder"},[_c('span',{staticClass:"align-middle"}),_vm._v(_vm._s(_vm.task.title)+" ")])]),(_vm.task.reference_type && _vm.task.reference_type != null)?_c('div',{staticClass:"d-flex align-items-center justify-content-start mt-75"},[_c('b-badge',{staticClass:"ml-25",attrs:{"variant":"primary"}},[_vm._v(_vm._s(_vm.getTaskReferenceName(_vm.task.reference_type)))])],1):_vm._e()]),_c('div',{staticClass:"d-flex justify-content-end align-items-center"},[_c('b-button',{attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":function($event){return _vm.closeSidebar()}}},[_c('feather-icon',{attrs:{"size":"24","icon":"XIcon"}})],1)],1)]),(_vm.task.reference_type && _vm.task.reference_type != null)?_c('div',{staticClass:"d-flex flex-row justify-content-center align-items-center content-sidebar-header px-2 py-1"},[(
          _vm.task.reference_type &&
          _vm.task.reference_type == 'App\\Models\\GapAssessmentResponse'
        )?_c('b-button',{staticClass:"my-75 mx-75",attrs:{"disabled":_vm.taskForm.status == 4,"variant":"primary"},on:{"click":function($event){return _vm.handleUploadEvidencClick(_vm.task.callback_url)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckSquareIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Upload Evidence")])],1):_vm._e(),(
          _vm.task.reference_type &&
          _vm.task.reference_type == 'App\\Models\\GapAssessmentResponse'
        )?_c('b-button',{staticClass:"my-75 mx-75",attrs:{"disabled":_vm.taskForm.status == 4,"variant":"success"},on:{"click":_vm.handleGotoAssessmentClick}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"ArrowUpLeftIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Go to Assessment")])],1):_vm._e()],1):_vm._e(),_c('div',{staticClass:"card-body mt-1"},[_c('validation-observer',{ref:"task_form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmitClick.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Name"}},[_c('template',{slot:"label"},[_vm._v(" Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Name","rules":"required|min:4","vid":"title"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","state":errors.length > 0 ? false : null,"name":"Name","disabled":_vm.task.creator &&
                  _vm.task.creator.user_id !== _vm.$store.state.app.user.user_id},model:{value:(_vm.taskForm.title),callback:function ($$v) {_vm.$set(_vm.taskForm, "title", $$v)},expression:"taskForm.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2),_c('b-form-group',{attrs:{"label":"Description"}},[_c('validation-provider',{attrs:{"name":"Description","vid":"description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"description","disabled":_vm.task.creator &&
                  _vm.task.creator.user_id !== _vm.$store.state.app.user.user_id,"debounce":"500","rows":"3","state":errors.length > 0 ? false : null,"name":"description"},model:{value:(_vm.taskForm.description),callback:function ($$v) {_vm.$set(_vm.taskForm, "description", $$v)},expression:"taskForm.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"mt-1",attrs:{"label":"Priority"}},[_c('template',{slot:"label"},[_vm._v(" Priority "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Priority","rules":"required","vid":"priority"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.priorityOptions,"disabled":_vm.task.creator &&
                  _vm.task.creator.user_id !== _vm.$store.state.app.user.user_id},model:{value:(_vm.taskForm.priority),callback:function ($$v) {_vm.$set(_vm.taskForm, "priority", $$v)},expression:"taskForm.priority"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2),_c('b-form-group',{staticClass:"mt-1",attrs:{"label":"Status"}},[_c('template',{slot:"label"},[_vm._v(" Status "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Status","rules":"required","vid":"status"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.task.creator &&
                  _vm.task.creator.user_id !== _vm.$store.state.app.user.user_id
                    ? _vm.statusOptionsAssigneUser
                    : _vm.statusOptions},model:{value:(_vm.taskForm.status),callback:function ($$v) {_vm.$set(_vm.taskForm, "status", $$v)},expression:"taskForm.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2),_c('b-form-group',{staticClass:"mt-1",attrs:{"label":"Assignee"}},[_c('template',{slot:"label"},[_vm._v(" Assignee "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Assignee","rules":"required","vid":"assignee"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.assigneeOptions,"disabled":_vm.taskForm.status == 4 &&
                  _vm.task.creator &&
                  _vm.task.creator.user_id !== _vm.$store.state.app.user.user_id},model:{value:(_vm.taskForm.assignee),callback:function ($$v) {_vm.$set(_vm.taskForm, "assignee", $$v)},expression:"taskForm.assignee"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2),_c('b-form-group',{staticClass:"mt-1",attrs:{"label":"Due Date"}},[_c('template',{slot:"label"},[_vm._v(" Due Date "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Due Date","rules":"required","vid":"due_date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"id":"due-date","min":_vm.minDueDate,"disabled":_vm.task.creator &&
                  _vm.task.creator.user_id !== _vm.$store.state.app.user.user_id},model:{value:(_vm.taskForm.due_date),callback:function ($$v) {_vm.$set(_vm.taskForm, "due_date", $$v)},expression:"taskForm.due_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],2),_c('b-button',{staticClass:"ml-0 mt-75",attrs:{"type":"submit","variant":"primary","disabled":invalid}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CheckIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Update")])],1)],1)]}}],null,false,1759417196)})],1),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header py-50"},[_c('p',{staticClass:"card-title mb-0"},[_vm._v("Comments")])]),_c('div',{staticClass:"card-body"},[_c('comments-section',{attrs:{"comments":_vm.task.comments,"newCommentCallback":_vm.handleAddNewComment,"showCommentLoading":_vm.showCommentLoading}})],1)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }