

export default {

    methods: {
        getComments(taskId, params) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    url: `${process.env.VUE_APP_BASEURL}/tasks/${taskId}/comments`,
                    params: params,
                };
                this.$http(options)
                    .then(res => resolve(res))
                    .catch(err => reject(err))
            })
        },

        addNewComment(taskId, obj) {
            return new Promise((resolve, reject) => {
                const bodyFormData = new FormData();
                bodyFormData.append("message", obj.comment);
                const options = {
                    method: "POST",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/tasks/${taskId}/add-comment`,
                    data: bodyFormData,
                };
                this.$http(options)
                    .then(res => resolve(res))
                    .catch(err => reject(err))
            })
        }
    }
}